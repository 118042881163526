import React, { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthProvider";
import "./styles.scss";

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorOrSuccessMessage, setErrorOrSuccessMessage] = useState("");
  const auth = useContext(AuthContext);

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setErrorOrSuccessMessage("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
            name: username,
            email: email,
            password: password,
          }),
        }
      );

      if (response.status === 200) {
        setErrorOrSuccessMessage("Registration successful");
        console.log("User created successfully.");
      } else {
        setErrorOrSuccessMessage("Registration failed");
        console.error("Registration failed");
      }
    } catch (error) {
      setErrorOrSuccessMessage("Registration failed");
      console.error(error);
    }
  };

  if (!auth.isAuthenticated()) {
    return (
      <div className="register-page">
        <div className="register-container">
          <input
            className="input-text"
            type="text"
            id="username"
            value={username}
            placeholder="Nume:"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="input-text"
            type="email"
            id="email"
            value={email}
            placeholder="Adresa de e-mail"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="input-text"
            type="password"
            id="password"
            value={password}
            placeholder="Parola"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="input-text"
            type="password"
            id="confirm-password"
            value={confirmPassword}
            placeholder="Confirmă parola"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errorOrSuccessMessage && (
            <div className="error-message">{errorOrSuccessMessage}</div>
          )}
          <div className="button-group">
            <button className="register-button" onClick={handleRegister}>
              Înscrie-te
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
