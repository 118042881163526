import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/terms-and-conditions">Termeni şi condiţii</Link>
        <Link to="/privacy-policy">Politică de confidențialitate</Link>
        <Link to="/cookies-policy">Politica privind fisierele cookies</Link>
        <a
          href="https://www.facebook.com/profile.php?id=100095277920349"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/musitopiaunage/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <Link to="/contact">Contact</Link>
      </div>
      <div className="footer-text">
        <p>&copy; {new Date().getFullYear()} MusiTopia</p>
      </div>
    </footer>
  );
}

export default Footer;
