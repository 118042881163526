import React from 'react';
import { Link } from 'react-router-dom';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import './styles.scss';

function DashboardUsers() {
  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="dashboard-home">
        <div className="long-rectangle">
          <span className="dashboard-text">Utilizatori</span>
        </div>

        <div className="dashboard-items">
          <Link to="/dashboard/users/list" className="dashboard-item yellow">
            <span>Users</span>
          </Link>
          <Link
            to="/dashboard/contact-messages"
            className="dashboard-item blue"
          >
            <span>Mesaje</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DashboardUsers;
