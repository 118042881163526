import React from 'react';
import './styles.scss';

function TermsAndConditions() {
  return (
    <div className="terms-conditions-container">
      <div className="terms-conditions">
        <div className="terms-content">
          <h1>Termeni și Condiții</h1>

          <h2>I. TERMENI ȘI CONDIȚII</h2>
          <p>
            Vizitarea sau utilizarea platformei web MusiTopia implică acceptarea
            termenilor și condițiilor de utilizare detaliate mai jos. Pentru a
            folosi în bune condiții platforma, vă recomandăm să citiți cu
            atenție acești Termeni și Condiții.
          </p>

          <h2>II. INFORMAȚII GENERALE</h2>
          <p>
            MusiTopia este o platformă web cu scop academic, destinată în
            special studenților, care oferă cursuri și teste cu conținut
            original și cu caracter educativ.
          </p>

          <h2>III. DESCRIEREA SERVICIILOR</h2>
          <p>
            MusiTopia oferă utilizatorilor acces la o varietate de cursuri și
            teste educaționale. Toate materialele disponibile pe platformă sunt
            originale și au scopul de a facilita procesul de învățare și
            dezvoltare academică.
          </p>

          <h2>IV. DREPTURI DE AUTOR ȘI PROPRIETATE INTELECTUALĂ</h2>
          <p>
            Toate materialele (texte, imagini, video, grafică, logo-uri)
            prezente pe MusiTopia sunt proprietatea intelectuală a platformei și
            sunt protejate de legea drepturilor de autor. Este interzisă
            copierea, distribuirea, publicarea sau modificarea materialelor fără
            acordul prealabil scris al MusiTopia sau al autorilor.
          </p>

          <h2>V. CONDIȚII DE UTILIZARE</h2>
          <p>
            Utilizatorii platformei MusiTopia se angajează să respecte
            următoarele condiții:
          </p>
          <p>
            <strong>Utilizare Responsabilă:</strong> Să folosească platforma în
            scopuri educative și să nu utilizeze conținutul în moduri care ar
            putea aduce prejudicii altor utilizatori sau MusiTopia.
          </p>
          <p>
            <strong>Respectarea Proprietății Intelectuale:</strong> Să nu
            distribuie sau să partajeze conținutul platformei fără permisiunea
            scrisă a MusiTopia sau al autorilor.
          </p>
          <p>
            <strong>Conduită Apropiată:</strong> Să nu posteze materiale
            ilegale, defăimătoare, obscene sau care încalcă drepturile altor
            persoane.
          </p>
          <p>
            <strong>Securitatea Contului:</strong> Să mențină confidențialitatea
            informațiilor de autentificare și să anunțe imediat MusiTopia în
            cazul oricărei utilizări neautorizate a contului.
          </p>

          <h2>VI. LIMITAREA RĂSPUNDERII</h2>
          <p>
            MusiTopia depune eforturi constante pentru a asigura acuratețea și
            actualitatea informațiilor prezentate pe platformă. Cu toate
            acestea, nu garantăm că toate materialele sunt lipsite de erori sau
            omisiuni. MusiTopia nu va fi responsabilă pentru:
          </p>
          <p>
            <strong>Erori Tehnice:</strong> Eventuale disfuncționalități ale
            platformei sau erori tehnice independente de voința noastră.
          </p>
          <p>
            <strong>Conținutul Terților:</strong> Informațiile sau materialele
            furnizate de terți, inclusiv link-uri către alte site-uri sau
            resurse.
          </p>
          <p>
            <strong>Daune Indirecte:</strong> Orice pierderi sau daune indirecte
            rezultate din utilizarea sau incapacitatea de a utiliza platforma.
          </p>

          <h2>VII. LINK-URI CĂTRE SITE-URI TERȚE</h2>
          <p>
            Platforma MusiTopia poate conține link-uri către site-uri externe.
            MusiTopia nu își asumă responsabilitatea pentru conținutul sau
            practicile de confidențialitate ale acestor site-uri și nu va fi
            responsabilă pentru eventualele daune sau pierderi cauzate de
            accesarea lor.
          </p>

          <h2>VII. FORȚA MAJORĂ</h2>
          <p>
            MusiTopia nu poate fi considerată responsabilă pentru orice
            întârziere sau eroare rezultată direct sau indirect din cauze care
            nu depind de voința sa, cum ar fi:
          </p>
          <p>
            <strong>Probleme Tehnice Majore:</strong> Defecțiuni ale
            echipamentului tehnic, lipsa conexiunii la internet sau acces
            neautorizat.
          </p>
          <p>
            <strong>Cazuri de Forță Majoră:</strong> Evenimente neprevăzute
            precum dezastre naturale, conflicte sau alte situații care împiedică
            funcționarea normală a platformei.
          </p>
          <h2>IX. MODIFICAREA TERMENILOR ȘI CONDIȚIILOR</h2>
          <p>
            MusiTopia își rezervă dreptul de a modifica oricând acești Termeni
            și Condiții, fără o notificare prealabilă. Modificările vor fi
            afișate pe această pagină, iar continuarea utilizării platformei
            implică acceptarea acestor modificări.
          </p>

          <h2>X. LEGEA APLICABILĂ ȘI JURISDICȚIA</h2>
          <p>
            Acești Termeni și Condiții sunt guvernați de legile din România.
            Orice dispută apărută în legătură cu utilizarea platformei va fi
            soluționată pe cale amiabilă, iar în caz contrar, va fi înaintată
            instanțelor competente din România.
          </p>

          <h2>XI. CONTACT</h2>
          <p>
            Dacă aveți întrebări, sugestii sau aveți nevoie de informații
            suplimentare despre acești Termeni și Condiții, vă rugăm să ne
            contactați:
          </p>
          <p>Email: musitopia.unage@gmail.com</p>
          <p>
            Formular de Contact: Disponibil pe site-ul nostru la secțiunea
            Contact.
          </p>
          <p>
            Vom depune toate eforturile pentru a vă răspunde în cel mai scurt
            timp posibil.
          </p>
          <p>
            Vă mulțumim că alegeți MusiTopia și vă dorim o experiență
            educațională plăcută pe platforma noastră!
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
