import React from 'react';
import './styles.scss';

function CookiesPolicy() {
  return (
    <div className="cookies-policy-container">
      <div className="cookies-policy">
        <div className="cookies-content">
          <h1>Politica privind Fișierele Cookie</h1>

          <h2>1. Introducere</h2>
          <p>
            Prezenta Politică privind fișierele cookie se aplică tuturor
            utilizatorilor platformei web MusiTopia. Informațiile prezentate în
            continuare au ca scop informarea utilizatorilor acestei platforme cu
            privire la plasarea, utilizarea și administrarea cookie-urilor de
            către MusiTopia în contextul navigării pe platformă.
          </p>

          <h2>2. Ce sunt cookie-urile?</h2>
          <p>
            Folosim termenul „cookie”-uri pentru a ne referi la modulele cookie
            și la tehnologii similare prin intermediul cărora pot fi colectate
            informații în mod automat.
          </p>
          <p>
            Un „cookie” (cunoscut și sub denumirea de „browser cookie” sau „HTTP
            cookie”) reprezintă un fișier de mici dimensiuni, format din litere
            și numere, care va fi stocat pe computerul, dispozitivul mobil sau
            pe alte echipamente ale unui utilizator prin intermediul cărora se
            accesează internetul.
          </p>
          <p>
            Cookie-urile sunt instalate prin solicitarea emisă de un web-server
            către un browser (de ex.: Internet Explorer, Firefox, Chrome).
            Cookie-urile odată instalate au o durată de existență determinată,
            rămânând „pasive”, în sensul că nu conțin programe software, viruși
            sau spyware și nu vor accesa informațiile de pe hard disk-ul
            utilizatorului.
          </p>
          <p>
            Un cookie este format din două părți: numele cookie-ului și
            conținutul sau valoarea acestuia.
          </p>
          <p>
            Din punct de vedere tehnic, doar web-serverul care a trimis
            cookie-ul îl poate accesa din nou în momentul în care utilizatorul
            se întoarce pe platforma asociată web-serverului respectiv.
          </p>

          <h2>
            3. Pentru ce scopuri sunt utilizate cookie-urile prin intermediul
            acestei platforme
          </h2>
          <p>
            Cookie-urile sunt utilizate pentru a furniza utilizatorilor
            platformei MusiTopia o experiență optimă de navigare și servicii
            adaptate nevoilor și intereselor fiecărui utilizator, și anume
            pentru:
          </p>
          <ul>
            <li>
              Autentificare și Securitate: Cookie-urile sunt esențiale pentru
              autentificare și stocarea token-ului necesar comunicării cu
              backend-ul, asigurând astfel funcționalitatea și securitatea
              platformei.
            </li>
            <li>
              Îmbunătățirea Serviciilor: În viitor, cookie-urile pot fi
              utilizate pentru colectarea de date statistice anonime privind
              modul în care este utilizată platforma, în vederea îmbunătățirii
              serviciilor oferite și a experienței utilizatorilor.
            </li>
          </ul>
          <p>
            Pe baza feedback-ului obținut prin intermediul cookie-urilor
            referitor la modul de utilizare a platformei, MusiTopia poate adopta
            măsuri pentru a face platforma mai eficientă și mai accesibilă
            pentru utilizatori.
          </p>

          <h2>4. Care este durata de viață a cookie-urilor?</h2>
          <p>
            Durata de viață a cookie-urilor poate varia semnificativ, în funcție
            de scopul pentru care sunt plasate. Există următoarele categorii de
            cookie-uri care determină durata de viață a acestora:
          </p>
          <ul>
            <li>
              Cookie-uri de sesiune: Un „cookie de sesiune” este un cookie care
              este șters automat când utilizatorul își închide browserul.
            </li>
            <li>
              Cookie-uri persistente: Un „cookie persistent” este un cookie care
              rămâne stocat în dispozitivul utilizatorului până când atinge o
              anumită dată de expirare sau până la ștergerea acestuia de către
              utilizator.
            </li>
          </ul>

          <h2>5. Cookie-uri plasate de terți</h2>
          <p>
            În prezent, MusiTopia nu utilizează cookie-uri plasate de terți.
            Toate cookie-urile utilizate sunt esențiale pentru funcționarea
            platformei și sunt gestionate direct de către MusiTopia.
          </p>

          <h2>
            6. Ce cookie-uri sunt folosite prin intermediul acestei platforme
          </h2>
          <p>
            Prin utilizarea platformei MusiTopia pot fi plasate următoarele
            tipuri de cookie-uri:
          </p>
          <ul>
            <li>Cookie-uri esențiale pentru funcționare</li>
          </ul>
          <p>
            Prin acest tip de cookie-uri se asigură funcționarea corectă și
            securizată a platformei. Acestea sunt necesare pentru:
          </p>
          <ul>
            <li>
              Autentificare: Gestionarea sesiunilor de utilizator și menținerea
              autentificării pe parcursul navigării pe platformă.
            </li>
            <li>
              Securitate: Prevenirea accesului neautorizat și protejarea datelor
              utilizatorilor.
            </li>
          </ul>

          <h2>
            7. Ce tip de informații sunt stocate și accesate prin intermediul
            cookie-urilor?
          </h2>
          <p>
            Cookie-urile esențiale păstrează informații necesare pentru
            autentificarea utilizatorului și securitatea platformei. Acestea nu
            colectează informații personale care ar putea identifica direct un
            vizitator.
          </p>

          <h2>
            8. Gestionarea setărilor browserului în ceea ce privește
            cookie-urile
          </h2>
          <p>
            Puteți seta browserul dumneavoastră pentru a bloca sau a permite
            cookie-urile. Cu toate acestea, blocarea cookie-urilor esențiale
            poate duce la disfuncționalități ale platformei și la
            imposibilitatea de a vă autentifica sau utiliza anumite servicii
            oferite de MusiTopia.
          </p>

          <h2>9. Cum pot fi oprite cookie-urile?</h2>
          <p>
            Majoritatea browserelor vă permit să gestionați setările
            cookie-urilor. Rețineți că dezactivarea cookie-urilor esențiale
            poate afecta funcționalitatea platformei MusiTopia și vă poate
            împiedica să vă conectați la contul dumneavoastră sau să utilizați
            serviciile noastre.
          </p>
          <p>
            Pentru a modifica setările cookie-urilor, puteți accesa secțiunea
            „Opțiuni” sau „Preferințe” a browserului dumneavoastră. Mai multe
            informații pot fi găsite în documentația sau meniul de ajutor al
            browserului pe care îl utilizați.
          </p>

          <p>
            Vă asigurăm că utilizăm cookie-uri doar în scopul de a vă oferi o
            experiență optimă și securizată pe platforma MusiTopia. Pentru mai
            multe informații sau întrebări legate de politica noastră privind
            cookie-urile, ne puteți contacta la adresa de email:
            contact@musitopia.ro.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CookiesPolicy;
