import {
  faArrowLeft,
  faArrowRight,
  faHandPointer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import "./styles.scss";

const FlipHint = React.memo(({ direction, icon1, icon2 }) => (
  <div className={`flip-hint ${direction}`} onClick={(e) => e.preventDefault()}>
    <FontAwesomeIcon icon={icon1} />
    <span>Flip</span>
    <FontAwesomeIcon icon={icon2} />
  </div>
));

const Book = React.memo(({ title, paragraphs }) => {
  const MOBILE_BREAKPOINT = 768;
  const [forceUpdate, setForceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const dimensionsRef = useRef({
    width: 700,
    height: 500,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsLoading(true);
      if (window.innerWidth < MOBILE_BREAKPOINT) {
        dimensionsRef.current = {
          width: Math.min(window.innerWidth * 0.8, 700),
          height: Math.min(window.innerHeight * 0.7, 500),
        };
      } else {
        dimensionsRef.current = {
          width: 700,
          height: 500,
        };
      }
      setForceUpdate((prev) => prev + 1);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle loading state after remount
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);
    return () => clearTimeout(timer);
  }, [forceUpdate]);

  const pages = useMemo(
    () => [
      <div className="page cover" key="cover">
        <h1>{title}</h1>
      </div>,
      ...paragraphs.map((paragraph, index) => (
        <div className="page" key={index}>
          <p>{paragraph}</p>
        </div>
      )),
    ],
    [title, paragraphs]
  );

  const handlePageFlip = (page) => {
    // console.log("Page flipped to:", page);
  };

  return (
    <div className={`book ${isLoading ? "loading" : ""}`}>
      <HTMLFlipBook
        key={forceUpdate} // Force remount on dimension change
        width={dimensionsRef.current.width}
        height={dimensionsRef.current.height}
        onFlip={handlePageFlip}
        className="book-pages"
        showCover={true}
        maxShadowOpacity={0.3}
        useMouseEvents={true}
      >
        {pages}
      </HTMLFlipBook>
      <div className="flip-hints">
        <FlipHint direction="right" icon1={faHandPointer} icon2={faArrowLeft} />
        <FlipHint direction="left" icon1={faArrowRight} icon2={faHandPointer} />
      </div>
    </div>
  );
});

export default Book;
