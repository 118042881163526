import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import './styles.scss';

function EditUser() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [valid, setValid] = useState(false);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const token = auth.token;
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchUser();
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchUser = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
            token: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Eroare la preluarea utilizatorului.');
      }
      const data = await response.json();
      setUser(data);
      setName(data.name);
      setEmail(data.email);
      setRole(data.role._id);
      setValid(data.valid);
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/roles`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
            token: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Eroare la preluarea rolurilor.');
      }
      const data = await response.json();
      setRoles(data);
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/${userId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
            token: token,
          },
          body: JSON.stringify({ name, email, role, valid }),
        }
      );
      if (!response.ok) {
        throw new Error('Eroare la actualizarea utilizatorului.');
      }
      navigate('/dashboard/users/list');
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="edit-user__container">
        <div className="edit-user__title">
          <h1 className="edit-user__title__text">Editare Utilizator</h1>
        </div>
        {error && <div className="error-message">{error}</div>}
        {user && (
          <form className="edit-user__form" onSubmit={handleUpdate}>
            <div className="form-group-users">
              <label className="users-form-label" htmlFor="name">
                Nume:
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group-users">
              <label className="users-form-label" htmlFor="email">
                Email:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group-users">
              <label className="users-form-label" htmlFor="role">
                Rol:
              </label>
              <select
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                {roles.map((roleItem) => (
                  <option key={roleItem._id} value={roleItem._id}>
                    {roleItem.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group-users">
              <label className="users-form-label" htmlFor="valid">
                Activ:
              </label>
              <input
                type="checkbox"
                id="valid"
                checked={valid}
                onChange={(e) => setValid(e.target.checked)}
              />
            </div>
            <button type="submit" className="edit-user__button">
              Actualizează
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditUser;
