import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import './styles.scss';

function DashboardContactMessages() {
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const token = auth.token;
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchMessages(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const fetchMessages = async (page) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/contact-messages/${perPage}/${page}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
            token: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Eroare la preluarea mesajelor de contact.');
      }
      const data = await response.json();
      setMessages(data.data);
      setTotalPages(data.totalPages);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const viewMessage = (messageId) => {
    navigate(`/dashboard/contact-messages/view/${messageId}`);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="contact-messages__container">
        <div className="contact-messages__title">
          <h1 className="contact-messages__title__text">Mesaje de Contact</h1>
        </div>
        <div className="contact-messages__table">
          {/* Antetul coloanelor */}
          <div className="contact-messages__header">
            <span>Nume</span>
            <span>Email</span>
            <span>Telefon</span>
            <span>Acțiuni</span>
          </div>
          {/* Datele mesajelor de contact */}
          {messages.map((message) => (
            <div className="contact-messages__data-container" key={message._id}>
              <div className="contact-messages__data-item">
                <span>{message.name}</span>
                <span>{message.email}</span>
                <span>{message.phone}</span>
                <div className="contact-messages__data-buttons">
                  <button
                    className="contact-messages__data-buttons__view"
                    onClick={() => viewMessage(message._id)}
                  >
                    Vizualizează
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Butoanele de paginare */}
        <div className="contact-messages__buttons-container">
          <button
            className="contact-messages__buttons-container__button"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span className="pagination-info">
            Pagina {currentPage} din {totalPages}
          </span>
          <button
            className="contact-messages__buttons-container__button"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            Următor
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashboardContactMessages;
