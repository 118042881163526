import React from 'react';
import './styles.scss';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy">
        <div className="privacy-content">
          <h1>Politica de confidențialitate</h1>

          <h2>I. Informații Generale</h2>
          <p>
            Confidențialitatea datelor dumneavoastră cu caracter personal este
            una dintre preocupările principale ale proprietarului platformei
            MusiTopia, în calitate de operator de date. Acest document are rolul
            de a vă informa cu privire la prelucrarea datelor dumneavoastră cu
            caracter personal în contextul utilizării platformei web MusiTopia.
          </p>

          <h2>II. Categoriile de Date cu Caracter Personal Prelucrate</h2>
          <p>
            <strong>II.1.</strong> Dacă sunteți vizitator sau utilizator al
            platformei, vom prelucra datele personale pe care ni le furnizați în
            mod direct, cum ar fi informațiile oferite în secțiunea de contact
            sau în timpul comunicării cu echipa noastră, dar si informațiile ce
            survin de felul în care platforma este utilizată.
          </p>

          <h2>III. Scopurile și Temeiurile Prelucrării</h2>
          <p>
            <strong>III.1. Furnizarea Serviciilor Educaționale</strong>
            <br />
            Prelucrăm datele dumneavoastră pentru a vă oferi acces în mod
            gratuit la cursurile și testele disponibile pe Platformă, asigurând
            astfel o experiență educațională optimă si într-un mediu cat mai
            securizat.
            <br />
            <em>
              Temei Legal: Executarea unui contract la care sunteți parte
              (Termeni și Condiții).
            </em>
          </p>
          <p>
            <strong>III.2. Analize și Îmbunătățirea Serviciilor</strong>
            <br />
            Utilizăm datele colectate pentru a efectua analize și statistici
            privind modul de utilizare a Platformei, cu scopul de a îmbunătăți
            serviciile oferite și experiența utilizatorilor.
            <br />
            <em>
              Temei Legal: Interesul nostru legitim de a optimiza și dezvolta
              Platforma.
            </em>
          </p>
          <p>
            <strong>III.3. Comunicări și Marketing</strong>
            <br />
            Cu consimțământul dumneavoastră, vă putem transmite informații
            despre noi cursuri sau funcționalități ale Platformei MusiTopia.
            <br />
            <em>Temei Legal: Consimțământul dumneavoastră explicit.</em>
          </p>
          <h2>IV. Durata Prelucrării Datelor</h2>
          <p>
            Datele dumneavoastră cu caracter personal vor fi prelucrate pe
            durata necesară îndeplinirii scopurilor menționate mai sus sau în
            conformitate cu prevederile legale aplicabile.
          </p>

          <h2>V. Dezvăluirea Datelor cu Caracter Personal</h2>
          <p>
            Pentru a asigura funcționarea și dezvoltarea Platformei, este
            posibil să dezvăluim datele dumneavoastră către:
          </p>
          <p>
            <strong>Parteneri și Furnizori de Servicii:</strong> Entități care
            ne sprijină în operarea Platformei (de exemplu, furnizori de
            servicii IT).
          </p>
          <p>
            <strong>Autorități Publice:</strong> În cazul în care există o
            obligație legală în acest sens.
          </p>
          <p>
            Toate terțele părți cu care colaborăm sunt obligate să respecte
            confidențialitatea datelor și să le utilizeze în conformitate cu
            legislația în vigoare.
          </p>

          <h2>VI. Transferul Datelor cu Caracter Personal</h2>
          <p>
            Datele dumneavoastră sunt stocate și prelucrate în spațiul Uniunii
            Europene. În cazul în care ar fi necesar un transfer în afara UE, ne
            vom asigura că sunt respectate toate garanțiile legale pentru
            protecția datelor.
          </p>

          <h2>VII. Drepturile Dumneavoastră</h2>
          <p>
            În conformitate cu legislația aplicabilă, beneficiați de următoarele
            drepturi:
          </p>
          <p>
            <strong>Dreptul la informare:</strong> Să primiți detalii privind
            activitățile de prelucrare a datelor.
          </p>
          <p>
            <strong>Dreptul de acces:</strong> Să obțineți confirmarea că datele
            dumneavoastră sunt prelucrate și să aveți acces la acestea.
          </p>
          <p>
            <strong>Dreptul la rectificare:</strong> Să solicitați corectarea
            sau completarea datelor inexacte sau incomplete.
          </p>
          <p>
            <strong>Dreptul la ștergere:</strong> Să solicitați ștergerea
            datelor în anumite circumstanțe (de exemplu, dacă datele nu mai sunt
            necesare pentru scopurile menționate).
          </p>
          <p>
            <strong>Dreptul la restricționarea prelucrării:</strong> Să cereți
            limitarea prelucrării datelor în anumite situații.
          </p>
          <p>
            <strong>Dreptul la portabilitatea datelor:</strong> Să primiți
            datele într-un format structurat și să le transmiteți altui
            operator.
          </p>
          <p>
            <strong>Dreptul la opoziție:</strong> Să vă opuneți prelucrării
            datelor din motive legate de situația dumneavoastră particulară.
          </p>
          <p>
            <strong>Dreptul de a nu fi supus unei decizii automate:</strong> Să
            solicitați intervenție umană în cazul deciziilor bazate exclusiv pe
            prelucrarea automată.
          </p>
          <p>
            <strong>Dreptul de a depune o plângere:</strong> Să vă adresați
            Autorității Naționale de Supraveghere a Prelucrării Datelor cu
            Caracter Personal.
          </p>
          <p>
            Pentru a vă exercita drepturile, ne puteți contacta la adresa de
            email: musitopia.unage@gmail.com.
          </p>

          <h2>VIII. Securitatea Datelor</h2>
          <p>
            MusiTopia implementează măsuri tehnice și organizatorice adecvate
            pentru a proteja datele cu caracter personal împotriva accesului
            neautorizat, pierderii, distrugerii sau divulgării neautorizate.
          </p>

          <h2>IX. Utilizarea Cookie-urilor</h2>
          <p>
            Platforma utilizează cookie-uri esențiale pentru autentificare și
            stocarea token-ului necesar comunicării cu backend-ul. Aceste
            cookie-uri sunt necesare pentru funcționarea optimă a Platformei și
            nu pot fi dezactivate.
          </p>

          <h2>X. Modificări ale Politicii de Confidențialitate</h2>
          <p>
            Ne rezervăm dreptul de a actualiza această politică periodic. Orice
            modificări vor fi comunicate prin intermediul Platformei sau prin
            alte mijloace de comunicare.
          </p>
          <p>
            Vă mulțumim că alegeți MusiTopia și vă asigurăm de angajamentul
            nostru continuu pentru protejarea datelor dumneavoastră personale.
            Pentru orice întrebări sau nelămuriri, nu ezitați să ne contactați.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
