import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AboutUs from '../../pages/AboutUs/AboutUs';
import CourseGenerator from '../../pages/Admin/CourseGenerator/CourseGenerator';
import Dashboard from '../../pages/Admin/Dashboard/Dashboard';
import DashboardContactMessages from '../../pages/Admin/DashboardContactMessages/DashboardContactMessages';
import DashboardCourses from '../../pages/Admin/DashboardCourses/DashboardCourses';
import DashboardTests from '../../pages/Admin/DashboardTests/DashboardTests';
import DashboardUserList from '../../pages/Admin/DashboardUserList/DashboardUserList';
import DashboardUsers from '../../pages/Admin/DashboardUsers/DashboardUsers';
import EditCourses from '../../pages/Admin/EditCourses/EditCourses';
import EditTests from '../../pages/Admin/EditTests/EditTests';
import EditUser from '../../pages/Admin/EditUser/EditUser';
import TestCategories from '../../pages/Admin/TestCategories/TestCategories';
import TestGenerator from '../../pages/Admin/TestGenerator/TestGenerator';
import UpdateCourse from '../../pages/Admin/UpdateCourse/UpdateCourse';
import UpdateTest from '../../pages/Admin/UpdateTest/UpdateTest';
import ViewContactMessage from '../../pages/Admin/ViewContactMessage/ViewContactMessage';
import Contact from '../../pages/Contact/Contact';
import CookiesPolicy from '../../pages/CookiesPolicy/CookiesPolicy';
import Courses from '../../pages/Courses/Courses';
import Home from '../../pages/Home/Home';
import Login from '../../pages/MyAccount/Login/Login';
import NewPassword from '../../pages/MyAccount/NewPassword/NewPassword';
import Register from '../../pages/MyAccount/Register/Register';
import Validate from '../../pages/MyAccount/Validate/Validate';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import Quiz from '../../pages/Quiz/Quiz';
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions';
import Tests from '../../pages/Tests/Tests';
import ViewCourse from '../../pages/ViewCourse/ViewCourse';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/" element={<Home />} />
      <Route path="/tests/:option/:categoryId" element={<Tests />} />
      <Route path="/quiz/:id" element={<Quiz />} />
      <Route path="/log-in" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/validate/:userId" element={<Validate />} />
      <Route path="/reset-password/:userId" element={<NewPassword />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/tests" element={<DashboardTests />} />
      <Route path="/dashboard/courses" element={<DashboardCourses />} />
      <Route path="/dashboard/tests/create" element={<TestGenerator />} />
      <Route path="/dashboard/tests/edit" element={<EditTests />} />
      <Route path="/dashboard/test/edit/:testId" element={<UpdateTest />} />
      <Route path="/dashboard/tests/categories" element={<TestCategories />} />
      <Route path="/dashboard/courses/create" element={<CourseGenerator />} />
      <Route path="/dashboard/courses/edit" element={<EditCourses />} />
      <Route path="/courses/:option/:categoryId" element={<Courses />} />
      <Route path="/course/:courseId" element={<ViewCourse />} />
      <Route
        path="/dashboard/course/edit/:courseId"
        element={<UpdateCourse />}
      />
      <Route path="/dashboard/users" element={<DashboardUsers />} />
      <Route path="/dashboard/users/list" element={<DashboardUserList />} />
      <Route path="/dashboard/users/edit/:userId" element={<EditUser />} />
      <Route
        path="/dashboard/contact-messages"
        element={<DashboardContactMessages />}
      />
      <Route
        path="/dashboard/contact-messages/view/:id"
        element={<ViewContactMessage />}
      />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookies-policy" element={<CookiesPolicy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
