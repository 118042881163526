import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import './styles.scss';

function ViewContactMessage() {
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [error, setError] = useState('');
  const auth = useContext(AuthContext);
  const token = auth.token;
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMessage = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/contact-message/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
            token: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Eroare la preluarea mesajului de contact.');
      }
      const data = await response.json();
      setMessage(data);
    } catch (err) {
      setError(err.message);
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!message) {
    return <div className="loading">Se încarcă...</div>;
  }
  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="view-contact-message__container">
        <h1 className="view-contact-message__title">
          Detalii Mesaj de Contact
        </h1>
        <div className="view-contact-message__details">
          <p>
            <strong>Nume:</strong> {message.name}
          </p>
          <p>
            <strong>Email:</strong> {message.email}
          </p>
          <p>
            <strong>Telefon:</strong> {message.phone || 'N/A'}
          </p>
          <p>
            <strong>Data:</strong>{' '}
            {new Date(message.createdAt).toLocaleDateString()}
          </p>
          <p>
            <strong>Mesaj:</strong>
          </p>
          <div className="message-content">{message.message}</div>
        </div>
      </div>
    </div>
  );
}

export default ViewContactMessage;
