import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";

import Logo from "../../sources/Logo.jsx";
import { navigationButtons } from "../../translations/translations.js";
import { getCategoryOptions } from "../utils/helper";
import "./styles.scss";

function Header() {
  const [btn, setBtn] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const [showTests, setShowTests] = useState(false);
  const [showUserOptions, setUserOptions] = useState(false);
  const [testCategories, setTestCategories] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);
  const [lang, setLang] = useState("rom");
  const home = navigationButtons.firstPage[lang];
  const team = navigationButtons.team[lang];
  const courses = navigationButtons.courses[lang];
  const tests = navigationButtons.tests[lang];
  const account = navigationButtons.account[lang];
  const register = navigationButtons.register[lang];
  const login = navigationButtons.login[lang];
  const ref = useRef(null);

  const auth = useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setBtn(false);
        setShowCourses(false);
        setShowTests(false);
        setUserOptions(false);
      } else {
        setShowCourses(true);
        setShowTests(true);
        setUserOptions(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getCategoryOptions(
      setCourseCategories,
      `${process.env.REACT_APP_API_URL}/api/course-categories`
    );
    getCategoryOptions(
      setTestCategories,
      `${process.env.REACT_APP_API_URL}/api/test-categories`
    );
  }, []);

  const closeMenu = () => {
    setBtn(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btn && ref.current && !ref.current.contains(event.target)) {
        setBtn(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [btn]);

  return (
    <div className="header-wrap">
      <nav className="header">
        <Logo />
        <label htmlFor="btn" className="icon">
          <span className="fa fa-bars"></span>
        </label>
        <input
          type="checkbox"
          id="btn"
          checked={btn}
          onChange={() => {
            setBtn(!btn);
            setShowCourses(false);
            setShowTests(false);
            setUserOptions(false);
          }}
        />
        <ul ref={ref} className={btn ? "menu-active" : ""}>
          <li>
            <Link to="/" onClick={closeMenu}>
              {home}
            </Link>
          </li>
          <li>
            <Link to="/about-us" onClick={closeMenu}>
              {team}
            </Link>
          </li>
          <li>
            <label
              htmlFor="btn-1"
              className="show"
              onChange={() => setShowCourses(!showCourses)}
            >
              {courses}
            </label>
            <Link
              to="/cursuri"
              onMouseEnter={() => setShowCourses(true)}
              onClick={closeMenu}
            >
              {courses}
            </Link>
            <input
              type="checkbox"
              id="btn-1"
              checked={showCourses}
              onChange={() => setShowCourses(!showCourses)}
            />
            <ul
              className={`lists ${showCourses ? "show-inline show-block" : ""}`}
            >
              {courseCategories.length > 0 &&
                courseCategories.map((courseCategory, index) => (
                  <li key={index}>
                    <Link
                      to={`/courses/${courseCategory[lang]}/${courseCategory._id}`}
                      onClick={closeMenu}
                    >
                      {courses} {courseCategory[lang]}
                    </Link>
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <label
              htmlFor="btn-2"
              className="show"
              onChange={() => setShowTests(!showTests)}
            >
              {tests}
            </label>
            <Link
              to="/teste"
              onMouseOver={() => setShowTests(true)}
              onClick={closeMenu}
            >
              {tests}
            </Link>
            <input
              type="checkbox"
              id="btn-2"
              checked={showTests}
              onChange={() => setShowTests(!showTests)}
            />
            <ul
              className={`lists ${showTests ? "show-inline show-block" : ""}`}
            >
              {testCategories.length > 0 &&
                testCategories.map((testCategory, index) => (
                  <li key={index}>
                    <Link
                      to={`/tests/${testCategory[lang]}/${testCategory._id}`}
                      onClick={closeMenu}
                    >
                      {tests} {testCategory[lang]}
                    </Link>
                  </li>
                ))}
            </ul>
          </li>
          {auth.isAuthenticated() &&
            (auth.isAdmin() || auth.isSuperAdmin()) && (
              <li>
                <Link to="/dashboard" onClick={closeMenu}>
                  Dashboard
                </Link>
              </li>
            )}

          {!auth.isAuthenticated() ? (
            <>
              <li>
                <label
                  htmlFor="btn-3"
                  className="show"
                  onChange={() => setUserOptions(!showUserOptions)}
                >
                  {account}
                </label>
                <Link
                  to="/log-in"
                  onMouseEnter={() => setUserOptions(true)}
                  onClick={closeMenu}
                >
                  {account}
                </Link>
                <input
                  type="checkbox"
                  id="btn-3"
                  checked={showUserOptions}
                  onChange={() => setUserOptions(!showUserOptions)}
                />
                <ul
                  className={`lists ${
                    showUserOptions ? "show-inline show-block" : ""
                  }`}
                >
                  <li>
                    <Link to="/log-in" onClick={closeMenu}>
                      {login}
                    </Link>
                  </li>
                  <li>
                    <Link to="/register" onClick={closeMenu}>
                      {register}
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            <li>
              <Link to="/log-out" onClick={auth.logout}>
                Log Out
              </Link>
            </li>
          )}
          {auth.isAuthenticated() &&
            (auth.isAdmin() || auth.isSuperAdmin()) && (
              <li>
                <div className="switch-container">
                  <div className="option">RO</div>
                  <div className="switch-toggle">
                    <div className="onoffswitch">
                      <input
                        v-model="onoffswitch"
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="myonoffswitch"
                      ></input>
                      <label
                        className="onoffswitch-label"
                        htmlFor="myonoffswitch"
                      ></label>
                    </div>
                  </div>
                  <div className="option">EN</div>
                </div>
              </li>
            )}
        </ul>
      </nav>
    </div>
  );
}

export default Header;
