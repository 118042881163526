import React, { useState } from "react";
import Book from "../../components/utils/Book/Book";
import Slider from "../../components/utils/Slider/Slider.jsx";
import firstSliderImage from "../../sources/homepage-slider-1.jpg";
import secondSliderImage from "../../sources/homepage-slider-2.jpg";
import thirdSliderImage from "../../sources/homepage-slider-3.jpg";
import { homePageContent } from "../../translations/translations.js";
import "./styles.scss";

function Home() {
  const [lang, setLang] = useState("rom");
  const paragraphs = homePageContent.paragraphs.map(
    (paragraph) => paragraph[lang]
  );

  const images = [firstSliderImage, secondSliderImage, thirdSliderImage];
  const homePageTitle = "MusiTopia";

  return (
    <div className="home-landing-page">
      <div className="homepage-title">
        <div className="inner">
          <span>{homePageTitle}</span>
        </div>
        <div className="inner">
          <span>{homePageTitle}</span>
        </div>
      </div>
      <Slider images={images} className="slider-container" />
      <div className="home-container">
        <Book
          paragraphs={paragraphs}
          title="Prezentarea Proiectului MusiTopia"
        />
      </div>
    </div>
  );
}

export default Home;
