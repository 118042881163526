// src/pages/Admin/DashboardUsers/DashboardUserList.jsx
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import './styles.scss';

function DashboardUserList() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const token = auth.token;
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const fetchUsers = async (page) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/${perPage}/${page}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
            token: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Eroare la preluarea utilizatorilor.');
      }
      const data = await response.json();
      setUsers(data.data);
      setTotalPages(data.totalPages);
    } catch (err) {
      setError(err.message);
    }
  };

  const editUser = (userId) => {
    navigate(`/dashboard/users/edit/${userId}`);
  };

  const deleteUser = async (userId) => {
    if (window.confirm('Sigur doriți să ștergeți acest utilizator?')) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/user/${userId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'api-key': apiKey,
              token: token,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Eroare la ștergerea utilizatorului.');
        }
        // Reîmprospătați lista de utilizatori
        fetchUsers(currentPage);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="user-list__container">
        <div className="user-list__title">
          <h1 className="user-list__title__text">Lista Utilizatorilor</h1>
        </div>
        <div className="user-list__table">
          {/* Antetul coloanelor */}
          <div className="user-list__header">
            <span>Nume</span>
            <span>Email</span>
            <span>Rol</span>
            <span>Activ</span>
            <span>Acțiuni</span> {/* Adăugați această linie */}
          </div>
          {/* Datele utilizatorilor */}
          {users.map((user) => (
            <div className="user-list__data-container" key={user._id}>
              <div className="user-list__data-item">
                <span>{user.name}</span>
                <span>{user.email}</span>
                <span>{user.role.name}</span>
                <span>{user.valid ? 'Da' : 'Nu'}</span>
                <div className="user-list__data-buttons">
                  <FontAwesomeIcon
                    className="user-list__data-buttons__edit"
                    icon={faEdit}
                    onClick={() => editUser(user._id)}
                  />
                  <FontAwesomeIcon
                    className="user-list__data-buttons__delete"
                    icon={faTrash}
                    onClick={() => deleteUser(user._id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Butoanele de paginare */}
        <div className="user-list__buttons-container">
          <button
            className="user-list__buttons-container__button"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span className="pagination-info">
            Pagina {currentPage} din {totalPages}
          </span>
          <button
            className="user-list__buttons-container__button"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            Următor
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashboardUserList;
