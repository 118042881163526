import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(Cookies.get('token') || null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const storedToken = localStorage.getItem('token');
    if (storedUser && storedToken) {
      setUser(storedUser);
      setToken(storedToken);
    }
  }, []);

  const login = (token) => {
    Cookies.set('token', token, {
      expires: 7,
      secure: true,
      sameSite: 'Strict',
    });
    setToken(token);
  };

  const logout = () => {
    Cookies.remove('token');
    setToken(null);
  };

  const isAuthenticated = () => {
    return token !== null;
  };

  const isAdmin = () => {
    const decodedToken = jwt_decode(token);
    return decodedToken.role === 'admin';
  };

  const isSuperAdmin = () => {
    const decodedToken = jwt_decode(token);
    return decodedToken.role === 'administrator';
  };
  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        login,
        logout,
        isAuthenticated,
        isAdmin,
        isSuperAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
