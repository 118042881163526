import React, { useState } from 'react';
import emanuelaIzabelaVieriu from '../../sources/Emanuela-Izabela-Vieriu.jpg';
import mihaelaGeorgianaBalan from '../../sources/Mihaela-Georgiana-Balan.jpg';

import lucianCheran from '../../sources/Lucian-Cheran.jpg';
import paulPintilie from '../../sources/Paul-Pintilie.jpg';
import {
  aboutUsContent,
  firstTeamMember,
  fourthTeamMember,
  secondTeamMember,
  thirdTeamMember,
} from '../../translations/translations.js';

import './styles.scss';

function AboutUs() {
  const [lang, setLang] = useState('rom');
  const title = aboutUsContent.title[lang];
  const descriptions = aboutUsContent.descriptions;

  return (
    <>
      <div className="about-us-wrapper">
        <div className="about-us-container">
          <div className="about-us-content-description">
            <h2 className="about-us-title">{title}</h2>
            {descriptions.map((description, index) => {
              return (
                <h3 key={index} className="about-us-description">
                  {description[lang]}
                </h3>
              );
            })}
            <div class="about-us-image"></div>
          </div>
        </div>
      </div>
      <div className="first-team-member">
        <div className="first-team-member__text-wrapper">
          <img
            className="first-team-member__image"
            src={mihaelaGeorgianaBalan}
            alt="Mihaela-Georgiana Balan"
          />
          {firstTeamMember.text.map((item, index) => (
            <h4 key={index} className="first-team-member__text">
              {item[lang]}
            </h4>
          ))}
          <div className="first-team-member__list">
            {firstTeamMember.list.map((item, index) => (
              <h4 key={index} className="first-team-member__list__item">
                {item[lang]}
              </h4>
            ))}
          </div>
        </div>
      </div>
      <div className="second-team-member">
        <div className="second-team-member__text-wrapper">
          <img
            className="second-team-member__image"
            src={paulPintilie}
            alt="Paul Pintilie"
          />
          {secondTeamMember.text.map((item, index) => (
            <h4 key={index} className="first-team-member__text">
              {item[lang]}
            </h4>
          ))}
        </div>
      </div>
      <div className="third-team-member">
        <div className="third-team-member__text-wrapper">
          <img
            className="third-team-member__image"
            src={emanuelaIzabelaVieriu}
            alt="Emanuela Izabela Vieriu"
          />
          {thirdTeamMember.text.map((item, index) => (
            <h4 key={index} className="third-team-member__text">
              {item[lang]}
            </h4>
          ))}
        </div>
      </div>
      <div className="fourth-team-member">
        <div className="fourth-team-member__text-wrapper">
          <img
            className="fourth-team-member__image"
            src={lucianCheran}
            alt="Lucian Cheran"
          />
          {fourthTeamMember.text.slice(0, -1).map((item, index) => (
            <h4 key={index} className="fourth-team-member__text">
              {item[lang]}
            </h4>
          ))}
          <div className="fourth-team-member__list">
            {fourthTeamMember.list.map((item, index) => (
              <h4 key={index} className="first-team-member__list__item">
                {item[lang]}
              </h4>
            ))}
          </div>
          <h4 className="fourth-team-member__text">
            {fourthTeamMember.text[fourthTeamMember.text.length - 1][lang]}
          </h4>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
