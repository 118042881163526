import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import AppRoutes from "../routes/AppRoutes";
import AnimatedBackground from "../utils/AnimatedBackground/AnimatedBackground";
import "./styles.scss";

function AppContainer() {
  return (
    <>
      <div className="app-container">
        <Header />
        <AnimatedBackground />
        <div className="content">
          <AppRoutes />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default AppContainer;
