import React, { useMemo } from "react";
import "./styles.scss";

const AnimatedBackground = () => {
  const totalElements = 50;

  const elements = useMemo(() => {
    const els = [];
    for (let i = 0; i < totalElements; i++) {
      els.push(<div className="snow gg-music-note" key={`note-${i}`}></div>);
      els.push(<div className="snow gg-music" key={`music-${i}`}></div>);
    }
    return els;
  }, [totalElements]);

  return <div className="background-animation">{elements}</div>;
};

export default AnimatedBackground;
