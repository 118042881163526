import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthProvider";
import { loginPage } from "../../../translations/translations";
import { handleLogin } from "../helper";
import "./styles.scss";

function Login() {
  const [email, setEmail] = useState("");
  const [lang, setLang] = useState("rom");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const auth = useContext(AuthContext);

  const passwordChange = () => {
    setForgotPassword(true);
  };

  const requestNewPassword = async () => {
    console.log("request new password");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/new_password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!auth.isAuthenticated()) {
    return (
      <div className="login-page">
        <div className="login-container">
          <input
            className="input-text"
            type="text"
            id="email"
            placeholder={loginPage.email[lang]}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className={` ${forgotPassword ? "hide" : "input-text"}`}
            type="password"
            id="password"
            placeholder={loginPage.password[lang]}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className={` ${forgotPassword ? "hide" : "forgot-password"}`}>
            <span onClick={passwordChange}>
              {loginPage.forgotPassword[lang]}
            </span>
          </div>
          <div className="button-group">
            <button
              className="login-button"
              onClick={() =>
                forgotPassword
                  ? requestNewPassword()
                  : handleLogin(email, password, auth)
              }
            >
              {forgotPassword
                ? loginPage.newPassword[lang]
                : loginPage.login[lang]}
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
}

export default Login;
