import React, { useState } from 'react';
import './styles.scss';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errorOrSuccessMessage, setErrorOrSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/contact`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({ name, email, phone, message }),
        }
      );

      const text = await response.text();
      let data;
      try {
        data = JSON.parse(text);
      } catch (error) {
        throw new Error(text);
      }

      if (response.ok) {
        setErrorOrSuccessMessage('Mesajul a fost trimis cu succes!');
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
      } else {
        setErrorOrSuccessMessage(`Eroare: ${data.message}`);
      }
    } catch (error) {
      setErrorOrSuccessMessage(`Eroare: ${error.message}`);
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <h1>Contactează-ne</h1>
        <form onSubmit={handleSubmit}>
          <input
            className="input-text"
            type="text"
            id="name"
            value={name}
            placeholder="Nume"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            className="input-text"
            type="email"
            id="email"
            value={email}
            placeholder="Adresa de e-mail"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            className="input-text"
            type="tel"
            id="phone"
            value={phone}
            placeholder="Număr de telefon (opțional)"
            onChange={(e) => setPhone(e.target.value)}
          />
          <textarea
            className="input-text"
            id="message"
            value={message}
            placeholder="Mesaj"
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          {errorOrSuccessMessage && (
            <div className="error-message">{errorOrSuccessMessage}</div>
          )}
          <div className="button-group">
            <button className="contact-button" type="submit">
              Trimite
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
